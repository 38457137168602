<template>
    <window-portal :open="dataProps.show" @close="() => {
        dataProps.show = false
        if(dataProps?.closeCallback){
            dataProps?.closeCallback()
        };
    }
        ">
        <div style="font-family: arial !important; margin-top: 10px; margin-bottom: 10px;">
            <center><div><p style="font-size: 30px;">Vista Previa de Reporte de Partidas Detallado</p></div></center>
            <br/>
            <center>
                <button class="btnclean" style="font-size: 18px;" @click="Print()">
                    Imprimir
                </button>
                &nbsp;&nbsp;
                <button class="btnclean" style="font-size: 18px;" @click="Download()">
                    Descargar
                </button>
            </center>
            <center><div v-if="loading"><p>Cargando...</p></div></center>
        </div>
        
        <!--div style="font-family: arial !important; margin-top: 10px; margin-bottom: 10px;">
            <center v-if="mailLabel">
                {{ mailLabel }}
            </center>
        </div-->
        
        <div class="book" style="margin: 0; padding: 0; background-color: rgb(105, 105, 105); font-family: arial !important; padding-top: 20px; padding-bottom: 20px;">
                <div style="margin: 0 auto; width: 1000px;">
                    <div ref="dataprint" style="margin: 0 !important; padding: 0 !important;">
                        <div v-for="(page, indexPage) in pages" :key="indexPage" style="position: relative; width: 1000px; height: 1294.1px; background: white;">
                            <hr/>
                            <div class="subpage" style="height: 1280px; padding-top: 20px; overflow: hidden;">
                                <div style="margin-left: 20px; margin-right: 20px;">
                                    <div>
                                        <div style="text-align: center; font-size: 12px;">
                                            "{{ dataProps.header.business.toUpperCase() }}"
                                            <br/>
                                            {{ valuesRows[ indexPage ].header?.partidaTipo }}
                                        </div>
                                    </div>
                                    <br>
                                    <div>
                                        <div style="float: left; font-size: 12px;">
                                            Partida No. {{ valuesRows[indexPage].header?.partidaNo }}
                                        </div>
                                        <div style="float: right; font-size: 12px;">
                                            Fecha de la Partida {{ formatAccountingItemDate( valuesRows[indexPage].header?.partidaFecha ) }}  
                                        </div>
                                    </div>
                                    <br>
                                    <table border="1" style="font-size: 10px; border-collapse: collapse; width: 100%; margin-top: 10px;">
                                        <tr>
                                            <td style="padding: 5px; font-weight: bold;">CUENTA</td>
                                            <td style="padding: 5px; font-weight: bold;">DESCRIPCIÓN</td>
                                            <td style="padding: 5px; font-weight: bold;">PARCIAL</td>
                                            <td style="padding: 5px; font-weight: bold;">CARGOS</td>
                                            <td style="padding: 5px; font-weight: bold;">ABONOS</td>
                                        </tr>

                                        <tr v-for="(item, index) in valuesRows[indexPage].details" :key="index">
                                            <td :style="`padding: 5px; border-top: none; border-bottom: none; ${ accountStyle( item.AccountLevel, item.id_account ) }`">{{ item.id_account ? item.account_code : '' }}</td>
                                            <td :style="`padding: 5px; border-top: none; border-bottom: none; ${ accountStyle( item.AccountLevel, item.id_account ) }`">{{ item.account_description }}</td>
                                            <td style="padding: 5px; border-top: none; border-bottom: none; text-align: right; ">{{ item.Parcial }}</td>
                                            <td style="padding: 5px; border-top: none; border-bottom: none; text-align: right; font-weight: bold;">{{ item.Cargos }}</td>
                                            <td style="padding: 5px; border-top: none; border-bottom: none; text-align: right; font-weight: bold;">{{ item.Abonos }}</td>
                                        </tr>
                                        <tr v-if="valuesRows[indexPage].finish">
                                            <td colspan="3"></td>
                                            <td style="padding: 5px; text-align: right; font-weight: bold;">{{ valuesRows[ indexPage ].header.totalCargos }}</td>
                                            <td style="padding: 5px; text-align: right; font-weight: bold;">{{ valuesRows[ indexPage ].header.totalAbonos }}</td>
                                        </tr>
                                    </table>

                                    <table style="font-size: 12px; border-collapse: collapse; width: 100%; margin-top: 10px;">
                                        <tr>
                                            <!-- Primera fila: El primer TD ocupa el 100% -->
                                            <td style="padding: 5px; width: 100%;" colspan="2" v-if="valuesRows[indexPage].finish">
                                                CONCEPTO: {{ valuesRows[indexPage].header.partidaConcepto }}
                                                <br/><br/><br/><br/>
                                            </td>
                                        </tr>
                                        <tr>
                                            <!-- Segunda fila: Los TD internos ocupan 50% cada uno -->
                                            <td style="text-align: center; width: 50%;" v-if="valuesRows[indexPage].finish">_______________________<br />HECHO POR</td>
                                            <td style="text-align: center; width: 50%;" v-if="valuesRows[indexPage].finish">_______________________<br />REVISADO POR</td>
                                        </tr>
                                    </table>
                                </div>
                                
                                <div style="position: absolute; top: 0; width: 100%; left: 900px">
                                    <p style="font-size:12px;">Pagina {{ indexPage+1 }} de {{ numPages }}</p>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
        </div>
    </window-portal>
</template>

<script>

import jsPDF from "jspdf"
import WindowPortal from "@/components/ReportsAndPrintings/WindowPortal.vue"
import { numberWithCommas } from '../../helpers/money';

export default {
    name: "PrintReportePartidasDetallado",
    props: {
        dataProps: {
            type: Object,
        },
    },
    components: {WindowPortal},
    data() {
        return {
            loading: false,
            numPages: 0,
            valuesRows: [],
        }
    },
    mounted() {
    },
    computed: {
        pages() {
            return Array.from({ length: this.numPages });
        },
        formatDateToDDMMYYYYStart() {
            if (/^\d{2}\/\d{2}\/\d{4}$/.test(this.dataProps.header.start)) {
                return this.dataProps.header.start;
            }
            const d = new Date(this.dataProps.header.start);
            if (isNaN(d.getTime())) {
                return 'Fecha inválida'; 
            }
            const day = d.getDate().toString().padStart(2, '0');
            const month = (d.getMonth() + 1).toString().padStart(2, '0'); // Los meses van de 0 (enero) a 11 (diciembre)
            const year = d.getFullYear();

            return `${day}/${month}/${year}`;
        },
        formatDateToDDMMYYYYEnd() {
            if (/^\d{2}\/\d{2}\/\d{4}$/.test(this.dataProps.header.end)) {
                return this.dataProps.header.end;
            }
            const d = new Date(this.dataProps.header.end);
            if (isNaN(d.getTime())) {
                return 'Fecha inválida'; 
            }
            const day = d.getDate().toString().padStart(2, '0');
            const month = (d.getMonth() + 1).toString().padStart(2, '0'); // Los meses van de 0 (enero) a 11 (diciembre)
            const year = d.getFullYear();

            return `${day}/${month}/${year}`;
        },
    },
    watch: {
        'dataProps.show'() {
            if(this.dataProps.show){
                setTimeout(async () => {
                    await this.calculatePagesOfDoc()
                }, 0);
            }
        }
    },
    methods: {
        formatAccountingItemDate( value ) {
            if (/^\d{2}\/\d{2}\/\d{4}$/.test(value)) {
                return value;
            }
            const d = new Date(value);
            if (isNaN(d.getTime())) {
                return 'Fecha inválida'; 
            }
            const day = d.getDate().toString().padStart(2, '0');
            const month = (d.getMonth() + 1).toString().padStart(2, '0'); // Los meses van de 0 (enero) a 11 (diciembre)
            const year = d.getFullYear();

            return `${day}/${month}/${year}`;
        },
        accountStyle( accountLevel, id_account ) {
            if( accountLevel === 3 ) {
                return 'font-size: 10px; font-weight: bold;';
            }
            if( accountLevel != 3 && id_account ) {
                return 'font-size: 9px;';
            }
            if( accountLevel != 3 && !id_account ) {
                return 'font-size: 8px;'
            }

        },
        reduceMethod( array, label ) {
            const initialValue = 0;
            const filteredArray = array.filter( current => current.AccountLevel === 3 );
            return numberWithCommas( filteredArray.reduce(
                ( accumulator, currentValue ) => {
                    if( currentValue[ label ] ) {
                        const valueToAccum = parseFloat( currentValue[ label ].replace(',','') );
                        return accumulator + valueToAccum;
                    }
                    return accumulator;
                },
                initialValue
            ) )
        },
        calculatePagesOfDoc() {
            let pages = 0
            let rows = 0
            let maxRowsPerPage = 50
            this.valuesRows[pages] = { header: {}, details: [], finish: false }
            
            try{
                const parents = this.dataProps.data

                for (let i = 0; i < parents.length; i++) {

                    const childrens = parents[i].accounting_entry_details
                    this.valuesRows[ pages ].header = {
                        ...parents[ i ],
                        partidaNo: parents[i].ID_Accounting_item,
                        partidaTipo: parents[i].AccountingEntryType?.AccountingEntryTypeName ? parents[i].AccountingEntryType.AccountingEntryTypeName.toUpperCase().replace( 'PARTIDAS', 'PARTIDA' ) : '',
                        partidaConcepto: parents[i].accounting_entry_comment,
                        partidaFecha: parents[i].accounting_entry_date,
                        totalCargos: this.reduceMethod( childrens, 'Cargos' ),
                        totalAbonos: this.reduceMethod( childrens, 'Abonos' )
                    };

                    for (let j = 0; j < childrens.length; j++) {
                        var currentItem = childrens[j]

                        if (j == childrens.length-1) {
                            this.valuesRows[ pages ].finish = true
                        }
                        else {
                            this.valuesRows[ pages ].finish = false
                        }
                        
                        this.valuesRows[pages].details.push(currentItem)
                        rows++;

                        if (rows >= maxRowsPerPage) {
                            pages++;
                            rows = 0;
                            if( j === childrens.length - 1 ) {
                                this.valuesRows[ pages ] = { header: {}, details: [], finish: false };
                            } else {
                                this.valuesRows[ pages ] = {
                                    header: {
                                        ...parents[ i ],
                                        partidaNo: parents[i].ID_Accounting_item,
                                        partidaTipo: parents[i].AccountingEntryType?.AccountingEntryTypeName ? parents[i].AccountingEntryType.AccountingEntryTypeName.toUpperCase().replace( 'PARTIDAS', 'PARTIDA' ) : '',
                                        partidaConcepto: parents[i].accounting_entry_comment,
                                        partidaFecha: parents[i].accounting_entry_date,
                                        totalCargos: this.reduceMethod( childrens, 'Cargos' ),
                                        totalAbonos: this.reduceMethod( childrens, 'Abonos' )
                                    },
                                    details: [],
                                    finish: false
                                };
                            }
                        }
                    }
                    pages++;
                    rows = 0;
                    this.valuesRows[pages] = { header: {}, details: [] };
                }

                this.valuesRows.pop();
            }
            catch(err) {
                console.log(err)
            }

            this.numPages = pages
        },

        async Print(){
            this.loading = true
            var doc = new jsPDF('p', 'pt', 'letter')
            await doc.html(this.$refs.dataprint, {
                callback: function(doc){
                    doc.output()
                },
                x: 0,
                y: 0,
                margin: [0, 0, 0, 0],
                html2canvas:{ 
                    scale: 0.612,
                    useCORS: true,
                    logging: false,
                    allowTaint: true
                },
            })
            
            //doc.deletePage(2)

            doc.autoPrint({variant: 'non-conform'});
            const blob = doc.output("bloburl")
            window.open(blob)

            this.loading = false
        },

        async Download(){
            this.loading = true
            var doc = new jsPDF('p', 'pt', 'letter')
            await doc.html(this.$refs.dataprint, {
                callback: function(doc){
                    doc.output()
                },
                x: 0,
                y: 0,
                margin: [0, 0, 0, 0],
                html2canvas:{ 
                    scale: 0.612,
                    useCORS: true,
                    logging: false,
                    allowTaint: true
                },
            });
            
            //doc.deletePage(2)
            doc.save("CorteDeCajaDiario.pdf"); 

            this.loading = false
        },
    }
}
</script>

<style scoped>
    .u-row .u-col-100{
        width: 100% !important;
    }
    .btnclean{
        border-radius: 15px;
        border: 1px solid white;
        background-color: #F29D35 !important;
        color: white !important;
    }

    .btnclose{
        border-radius: 15px;
        border: 1px solid white;
        background-color: rgb(255, 38, 38) !important;
        color: white !important;
       
    }

    * {
        font-family: Arial, Helvetica, sans-serif !important;
    }

 

</style>
